import React from 'react';
import { Share } from 'react-native';
import { connect } from 'react-redux';

import { history } from '../../../store/store';
import Layout from '../../../hoc/Layout/Light/Layout';
import Button from '../../../components/UI/button/button';
import keys from '../../../config/keys';
import API from '../../../utils/api';
import './Bravo.scss';

const Bravo = (props) => {
  const sendMessage = async(userName, userLink) => {
    try {
      await Share.share({
        title: '',
        message:
          `Hello, \n\n`+
          `Pour m'améliorer dans mon travail, je souhaite découvrir ou confirmer mes talents. `+
          `Pour cela je demande des feedbacks grâce à l'application Humoon. `+
          `Pourrais-tu prendre 5min top chrono pour me donner ton feedback : \n`+
          `${userLink} \n\n`+
          `PS : rassure toi, ton feedback est totalement anonyme, je ne verrai que le résultat global de tous les feedbacks reçus. \n\n`+
          `Merci beaucoup, \n`+
          `${userName}`
      });
    } catch (error) {
      alert(
        'Erreur lors de la demande de feedback : ' + error.message
      );
    }
  }

  const feedbackRequested = async() => {
    try {
      const userFbCountResult = await API.post('/user/feedbackRequested');
      if (userFbCountResult.status !== 200) {
        throw new Error('Response status unvalid : ' + userFbCountResult.data.msg);
      }
    } catch (error) {
      alert(
        'Erreur lors de la demande de feedback :' + error.message
      );
      return;
    }
  }

  const submitHandler = () => {
    sendMessage(props.user.first_name, props.feedbackLink);
    setTimeout(() => {
      feedbackRequested();
    }, 500);
  }

  return (
    <Layout>
      <div className='bravo-container'>
        <div className='slideBodyBravo'>
          <div className='content'>
            <h2 className='web'>
              Bravo, ton compte a bien été validé
            </h2>
            <p className='description'>
              Pour accéder aux services Humoon :
            </p>

            <div className='bravoListContainer'>
              <div className='listContent'>
                <div className='bulletList'></div>
                <p>Télécharge l’application et réalise ton auto-évaluation.</p>
              </div>
              <div className='listContent'>
                <div className='bulletList'></div>
                <p>Obtiens au moins 5 feedbacks (n’hésite pas à en demander plus).</p>
              </div>
            </div>
            
            <div className='disclaimer'>
              <div className='disclaimerContent'>
                <img
                  className='rocketShip'
                  alt='Rocket ship'
                  src={require('../../../assets/images/rocketShip.png')} />
                <p className='small'>
                  <span>A qui demander un feedback ?</span>
                  <br/>
                  Si un jour tu devais organiser ton pot de départ, pense aux personnes que tu inviterais.
                </p>
              </div>
            </div>

            <div className='btnContainer'>
              <Button clicked={() => submitHandler()}>
                Demander des feedbacks
              </Button>
            </div>
            <p className='link noThanksLink' onClick={() => history.push('/see-you-soon')}>
              Non merci, pas pour l’instant
            </p>
          </div>
        </div>

        <div className='motorcycleContainer'>
          <img
            className='motorcycle'
            alt='Motorcycle'
            src={require('../../../assets/images/motorcycle.png')} />
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = state => {
  return {
    feedbackLink: `${keys.feedbackUrl}/${state.user.link_url}`,
    user: state.user
  }
}

export default connect(mapStateToProps)(Bravo);