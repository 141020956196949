import React from 'react';
import Aux from '../../../hoc/auxiliary';
import './button.scss';

export default (props) => {
  let btnClasses = ["btn", props.btnType];
  if (props.classes) {
    btnClasses = [...btnClasses, ...props.classes];
  }
  return props.isDisabled ? (
    <Aux>
      <button
        onClick={props.clicked}
        className={btnClasses.join(" ")}
        disabled>
        {props.children}
      </button>
    </Aux>) : (
    <Aux>
      <button
        onClick={props.clicked}
        className={btnClasses.join(" ")}>
        {props.children}
      </button>
    </Aux>
  )
}