import * as ActionTypes from '../actions/actionTypes'

export const initialState = {
  isLoading: false,
  error: null,
  id: null,
  isAppleLogin: false,
  isGoogleLogin: false
}

const authSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    ...action.payload
  }
}

const authFailed = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.payload
  }
}

const updateUser = (state, action) => {
  return {...state, ...action.payload};
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOADING_START: return {...state, isLoading: true};
    case ActionTypes.LOADING_DONE: return {...state, isLoading: false};
    case ActionTypes.AUTH_START: return {...state, isLoading: true, error: null };
    case ActionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case ActionTypes.AUTH_FAILED: return authFailed(state, action);
    case ActionTypes.USER_UPDATE_SUCCESS: return updateUser(state, action);
    case ActionTypes.SET_USER: return updateUser(state, action);
    case ActionTypes.RESET_ERROR: return {...state, error: null};
    case ActionTypes.SET_IS_GOOGLE_LOGIN: return {...state, isGoogleLogin: true};
    case ActionTypes.SET_IS_APPLE_LOGIN: return {...state, isAppleLogin: true};
    case ActionTypes.RESET_STATE: return initialState;

    default:
      return state
  }
}
