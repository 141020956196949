import API from '../../utils/api';

import * as ActionTypes from './actionTypes';
import { history } from '../store';

export const resetState = () => ({
  type: ActionTypes.RESET_STATE
});

export const resetError = () => ({
  type: ActionTypes.RESET_ERROR
});

export const setIsAppleLogin = () => ({
  type: ActionTypes.SET_IS_APPLE_LOGIN
});

export const setIsGoogleLogin = () => ({
  type: ActionTypes.SET_IS_GOOGLE_LOGIN
});

const loadingStart = () => ({
  type: ActionTypes.LOADING_START
});

const loadingDone = () => ({
  type: ActionTypes.LOADING_DONE
});

const setUser = (userData) => {
  return {
    type: ActionTypes.USER_UPDATE_SUCCESS,
    payload: { ...userData }
  }
}

export const updateUser = (userInfo) => {
  return async dispatch => {
    try {
      const updateResponse = await API.put('/user', { ...userInfo, isWeb: true });
      dispatch(setUser({ ...updateResponse.data.user }));
    } catch (e) {
      console.log(e)
    }
  }
}

const authStart = () => {
  return {
    type: ActionTypes.AUTH_START
  }
}

const authSuccess = (userData) => {
  return {
    type: ActionTypes.AUTH_SUCCESS,
    payload: userData
  }
}

const authFailed = (error) => {
  return {
    type: ActionTypes.AUTH_FAILED,
    payload: error.message || error
  }
}

export const signUp = (userInfo) => {
  return async dispatch => {
    dispatch(authStart());
    try {
      const signupResult = await API.post('/signup/email', {...userInfo});
      if (signupResult.status !== 201 && signupResult.status !== 200) {
        throw new Error('Response status unvalid : ' + signupResult.status);
      }
      API.defaults.headers.common['Authorization'] = signupResult.data.token;
      dispatch(authSuccess(signupResult.data.user));
      dispatch(setUser(signupResult.data.user));
      history.push('/verifyEmail');
      dispatch(loadingDone());
    } catch (e) {
      const errorMsg = e.response ? getErrorMessage(e.response) : e;
      dispatch(authFailed(errorMsg));
    }
  }
}

export const singUpWithApple = (appleResponse, device) => {
  return async dispatch => {
    dispatch(loadingStart());
    try {
      let fullName;
      if (appleResponse.user) {
        fullName = {
          givenName: appleResponse.user.name.firstName,
          familyName: appleResponse.user.name.lastName
        };
      } else {
        fullName = {
          givenName: 'Apple',
          familyName: 'User'
        };
      }
      const signup = await API.post('/signup/apple', {
        identityToken: appleResponse.authorization.id_token,
        fullName: fullName,
        device: device
      });
      dispatch(authSuccess(signup.data.user));
      API.defaults.headers.common['Authorization'] = signup.data.token;
      dispatch(setIsAppleLogin());
      if (signup.data.msg.includes('created')) {
        if (signup.data.user.is_email_confirmed) {
          history.push('/bravo');
        } else {
          history.push('/verifyEmail');
        }
      } else {
        throw new Error('Un utilisateur existe déjà avec cet email');
      }
      dispatch(loadingDone());
    } catch (e) {
      dispatch(authFailed(e.response ? getErrorMessage(e.response) : e));
      dispatch(loadingDone());
    }
  }
}

export const singUpWithGoogle = (googleResponse, device) => {
  return async dispatch => {
    dispatch(loadingStart());
    try {
      const signupResponse = await API.post('/signup/google', {
        ...googleResponse.profileObj,
        device: device
      });
      dispatch(authSuccess(signupResponse.data.user));
      API.defaults.headers.common['Authorization'] = signupResponse.data.token;
      dispatch(setIsGoogleLogin());
      if (signupResponse.data.msg.includes('créé')) {
        if (signupResponse.data.user.is_email_confirmed) {
          history.push('/bravo');
        } else {
          history.push('/verifyEmail');
        }
      } else {
        throw new Error('Un utilisateur existe déjà avec cet email');
      }
      dispatch(loadingDone());
    } catch (e) {
      dispatch(authFailed(e.response ? getErrorMessage(e.response) : e));
      dispatch(loadingDone());
    }
  }
} 

export const signupWithLinkedin = (signupInfo) => {
  return async dispatch => {
    try {
      const signupResponse = await API.post('/signup/linkedin', { ...signupInfo });
      dispatch(authSuccess(signupResponse.data.user));
      API.defaults.headers.common['Authorization'] = signupResponse.data.token;
      history.push('/verifyEmail');
      dispatch(loadingDone());
    } catch (e) {
      console.log(e);
      dispatch(authFailed(e.response ? getErrorMessage(e.response) : e));
      dispatch(loadingDone());
    }
  }
} 

const getErrorMessage =  _error => {
  const { data, status } = _error;
  if(+status === 422 && data.errors) return data.errors[0].msg;
  if(data.msg) return data.msg;
  return 'Erreur inconnue, veuillez recommencer';
}

export const setUserPassword = (userInfo) => {
  return async dispatch => {
    try {
      const setPassResult = await API.post('/user/setPassword', { ...userInfo });
      const userObj = { ...setPassResult.data.user };
      dispatch(setUser(userObj));
      history.push('/verifyEmail');
    } catch (e) {
      dispatch(authFailed(e.response ? getErrorMessage(e.response) : e));
    }
  }
}

export const getUser = (successCallback) => {
  return async dispatch => {
    dispatch(loadingStart());
    try {
      const getUserResult = await API.get('/user');
      const userObj = { ...getUserResult.data.user };
      dispatch(setUser(userObj));
      if (userObj.is_email_confirmed && typeof successCallback === 'function') {
        successCallback()
      } else {
        throw new Error('Email not verify');
      }
      dispatch(loadingDone());
    } catch (e) {
      dispatch(authFailed(e.response ? getErrorMessage(e.response) : e.message));
      dispatch(loadingDone());
      return;
    }
  }
}

export const sendVerificationEmail = (email, noAlert) => {
  return async dispatch => {
    try {
      dispatch(loadingStart());
      await API.post('user/sendVerificationEmail', {email, isWeb: true});
      dispatch(loadingDone());
      if(!noAlert){
        alert('Email envoyé');
      }
    } catch (e) {
      dispatch(loadingDone());
      const errorMsg = e.response && e.response.data ? getErrorMessage(e.response) : "Une erreur s'est produite, merci de réessayer ulterieurement.";
      if(!noAlert){
       alert(
          'Erreur lors de l\'envoi : ' + errorMsg
        );
      }
    }
  }
}