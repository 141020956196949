// NOTE : If add new key don't forget to add it in docker/script/startup.sh file

const keys = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  appleClientId: process.env.REACT_APP_APPLE_CLIENT_ID,
  feedbackUrl: process.env.REACT_APP_FEEDBACK_URL,
  linkedinClientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  // apiKey: process.env.REACT_APP_API_KEY,
}

export default keys;
