import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Old views
// import Intro from '../views/Intro/Intro';
// import Auth from '../views/Auth/Auth';
// import Info from '../views/Info/Info';
// import Employer from '../views/Employer/Employer';
// import Mood from '../views/Mood/Mood';
// import Email from '../views/Email/Email';
// import Activity from '../views/Activity/Activity';
// import Notifications from '../views/Notifications/Notifications';
// import Congrats from '../views/Congrats/Congrats';

// Light views
import Intro from '../views/Light/Intro/Intro';
import VerifyEmail from '../views/Light/VerifyEmail/VerifyEmail';
import Bravo from '../views/Light/Bravo/Bravo';
import SeeYouSoon from '../views/Light/SeeYouSoon/SeeYouSoon';

export const defaultRoutes = (
  <Switch>
    <Route exact path='/' component={Intro} />
    {/* <Route exact path='/auth' component={Auth}/> */}
    <Redirect to='/' />
  </Switch>
);

export const authRoutes = (
  <Switch>
    <Route exact path='/' component={Intro} />
    <Route exact path='/intro' component={Intro} />
    <Route exact path='/verifyEmail' component={VerifyEmail} />
    <Route exact path='/bravo' component={Bravo} />
    <Route exact path='/see-you-soon' component={SeeYouSoon} />
    {/* <Route exact path='/auth' component={Auth}/>
    <Route exact path='/info' component={Info}/>
    <Route exact path='/activity' component={Activity}/>
    <Route exact path='/employer' component={Employer}/>
    <Route exact path='/mood' component={Mood}/>
    <Route exact path='/email' component={Email}/>
    <Route exact path='/notifications' component={Notifications}/>
    <Route exact path='/congrats' component={Congrats}/> */}
    <Redirect to='/' />
  </Switch>
);

