export const AUTH_START = 'AUTH_START';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const SET_USER = 'SET_USER';
export const LOADING_START = 'LOADING_START';
export const LOADING_DONE = 'LOADING_DONE';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_IS_APPLE_LOGIN = 'SET_IS_APPLE_LOGIN';
export const SET_IS_GOOGLE_LOGIN = 'SET_IS_GOOGLE_LOGIN';
export const RESET_STATE = 'RESET_STATE';
