import React from 'react';
import { useCurrentWidth } from '../../../utils/resize';

import Layout from '../../../hoc/Layout/Light/Layout';
import './SeeYouSoon.scss';

const SeeYouSoon = () => (
  <Layout>
    <div className='see-you-container'>
      <div className='slideBodySeeYou'>
        <div className='title-logo-container'>
          <h2>À bientôt sur</h2>
          <img
            className='humoon_logo'
            alt='Humoon logo'
            src={require('../../../assets/images/humoonLogo.png')} />
        </div>
      </div>

      <div className='motorcycleContainer'>
        <img
          className='motorcycle'
          alt='Motorcycle'
          src={useCurrentWidth() >= 416 ? require('../../../assets/images/motorcycle.png') : 
            require('../../../assets/images/smallMotorcycle.png')} />
      </div>
    </div>
  </Layout>
)

export default SeeYouSoon;