import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { defaultRoutes, authRoutes } from './routes/routes';

class App extends Component {

  render(){
    return this.props.isAuthenticated ? authRoutes : defaultRoutes;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.id !== null
  };
};

export default withRouter(connect(mapStateToProps)(App));
