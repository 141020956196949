import React, { useState, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import AppleSignin from 'react-apple-signin-auth';

import { signUp, singUpWithApple, singUpWithGoogle } from '../../../store/actions';
import Layout from '../../../hoc/Layout/Light/Layout';
import Button from '../../../components/UI/button/button';
import keys from '../../../config/keys';
import './Intro.scss';

const Intro = (props) => {
  const redirectURIApple = window.location.origin;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(props.error || null);
  const isValid = (email != null && password != null && email !== '' && password.length >= 8);
  const device = isDesktop ? 'DESKTOP' : 'MOBILE';

  const submitHandler = () => {
    setError(null);
    props.onSignUp({ email: email, device: device, password: password });
  }

  useEffect(() => setError(props.error), [props.error]);

  const handleKeyDownCloseKeyboard = (event) => {
    if (event.key === 'Enter') {
      document.activeElement.blur();
    }
  }

  return (
    <Layout>
      <div className='intro-container'>
        <div className='slideBodyIntro'>
          <div className='content'>
            <h2 className='web'>
              Décollage imminent
            </h2>
            <p className='description'>
              Pour commencer l’aventure Humoon, crée un compte en 5 secondes.
            </p>

            <form className='introForm' onSubmit={() => submitHandler()}>
              <p>Quel est ton email ?</p>
              <input type='text' onChange={e => setEmail(e.target.value)} value={email} placeholder='elon@mail.com'
                     onKeyDown={handleKeyDownCloseKeyboard}/>
              <p>Choisis un mot de passe</p>
              <input type='password' onChange={e => setPassword(e.target.value)} value={password} placeholder='Mot de passe'
                     onKeyDown={handleKeyDownCloseKeyboard}/>
            </form>
            {error && (
              <p className='small authError'>{error}</p>
            )}

            <div className='btnContainer'>
              <Button isDisabled={!isValid} clicked={() => submitHandler()}>
                Valider et envoyer
              </Button>
            </div>

            <div className='separator'>OU</div>

            <div className='socialButtons'>
              <GoogleLogin
                clientId={keys.googleClientId}
                buttonText="Continuer avec Google"
                onSuccess={(response) => props.onGoogleSignUp(response, device)}
                onFailure={(error) => {
                  console.log('Google failure');
                  console.log(error);
                }}
                cookiePolicy={'single_host_origin'}
                className='googleBtn' />
                  
              <AppleSignin
                authOptions={{
                  clientId: (String)(keys.appleClientId),
                  redirectURI: redirectURIApple,
                  scope: 'email name',
                  state: 'state',
                  nonce: 'nonce',
                  usePopup: true
                }}
                className='apple-auth-btn'
                buttonExtraChildren='Continuer avec Apple'
                onSuccess={(response) => props.onAppleSignUp(response, device)}
                onError={(error) => {
                  console.log('Apple failure');
                  console.log(error);
                }} />
            </div>
          </div>
        </div>

        <div className='floatingContainer'>
          <img
            className='floating'
            alt='Man floating with phone'
            src={require('../../../assets/images/floating.png')} />
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = state => {
  return {
    error: state.user.error
  }
}

const mapDispatchToProps = dispatch => {
  return  {
    onAppleSignUp: (appleResponse, device) => dispatch(singUpWithApple(appleResponse, device)),
    onGoogleSignUp: (googleResponse, device) => dispatch(singUpWithGoogle(googleResponse, device)),
    onSignUp: (userInfo) => dispatch(signUp(userInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Intro);