import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getUser, sendVerificationEmail } from '../../../store/actions';
import { history } from '../../../store/store';
import Layout from '../../../hoc/Layout/Light/Layout';
import Button from '../../../components/UI/button/button';
import './VerifyEmail.scss';

const VerifyEmail = (props) => {
  const [error, setError] = useState(props.user.error || null);

  useEffect(() => {
    if (!props.user.is_email_confirmed) {
      props.onSendEmail(props.user.email, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => setError(props.user.error), [props.user.error]);

  return (
    <Layout>
      <div className='verify-container'>
        <div className='slideBodyVerify'>
          <div className='content'>
            <h2 className='web'>
              Peux-tu vérifier ton email ?
            </h2>
            <p className='description'>
              Nous venons d’envoyer un email à l’adresse ci-dessous.
            </p>
            
            <h4 className='userEmail'>
              {props.user.email}
            </h4>
            <p className='link changeEmailLink' onClick={() => history.goBack()}>
              Changer mon email
            </p>

            <div className={error ? 'disclaimer' : 'disclaimer marginBx2'}>
              <div className='disclaimerContent'>
                <p className='small'>
                  Si tu ne trouves pas l’email, pense à jeter un oeil dans tes courriers indésirables ou spams.
                </p>
                <img
                  className='smallTree'
                  alt='Small tree'
                  src={require('../../../assets/images/smallTree.png')} />
              </div>
            </div>
            {error && (
              <p className='small verifyError'>{error}</p>
            )}

            <div className='btnContainer'>
              <Button clicked={() => props.onGetUser(() => history.push('/bravo'))}>
                Suivant
              </Button>
            </div>
            <p className='link reSendLink' onClick={() => props.onSendEmail(props.user.email)}>
              Je n’ai pas reçu l’email ? Renvoyer
            </p>
          </div>
        </div>

        <div className='treeContainer'>
          <img
            className='bigTree'
            alt='Big tree'
            src={require('../../../assets/images/tree.png')} />
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return  {
    onGetUser: (successCallback) => dispatch(getUser(successCallback)),
    onSendEmail: (email, alert) => dispatch(sendVerificationEmail(email, alert))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);