import React from 'react';
import { connect } from 'react-redux';
import { useCurrentWidth } from '../../../utils/resize';

import Aux from '../../auxiliary';
import Spinner from '../../../components/UI/spinner/spinner';
import './Layout.scss';

const Layout = props => {
  let width = useCurrentWidth();

  const getStyle = () => {
    let styles = 'slideContainer';
    if (width >= 416) {
      styles = 'slideContainer halfBg';
    } else {
      if (window.location.pathname === '/see-you-soon') {
        styles = 'slideContainer halfBg pink-bg';
      }
    }
    return styles;
  }

  return (
    <Aux>
      {width >= 416 && (
        <div className='half-bg'></div>
      )}
      <div className={getStyle()}>
        <div className='logoContainer'>
          <img
            className='humoonLogo'
            alt='Humoon'
            src={window.location.pathname === '/verifyEmail' ? 
              require('../../../assets/humoon_white.png') : require('../../../assets/humoon.png')
            }
          />
        </div>
        {props.isLoading ? <Spinner /> : props.children}
      </div>
    </Aux>
  );
}

const mapStateToProps = state => {
  return {
    isLoading: state.user.isLoading
  };
};

export default connect(mapStateToProps)(Layout);